<template>
  <v-list color="transparent">
    <v-list-item v-for="(obItem, sKey) in items" :key="`audit-data-${sKey}`">
      <v-list-item-subtitle v-text="$t(obItem.label)" />
      <v-list-item-title v-text="obItem.value" />
    </v-list-item>
  </v-list>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { filter, isNil, map, set } from "lodash";
import dayjs from "dayjs";
import { AuditItem } from "@/modules/audits/mixins/AuditsMixin";

@Component
export default class AuditDataList extends Vue {
  @Prop(Array) readonly value!: AuditItem[];
  @Prop({ type: Array, default: () => [] }) readonly omit!: string[];
  @Prop({ type: Array, default: () => [] }) readonly dates!: string[];

  arDates = ["created_at", "updated_at", "deleted_at", "valid_at"];

  get items() {
    const arItems = filter(this.value, (obItem: AuditItem) => {
      return !this.omit.includes(obItem.key) && !isNil(obItem.value);
    });

    return map(arItems, (obItem) => {
      if (this.arDates.includes(obItem.key) && !isNil(obItem.value)) {
        set(obItem, "value", dayjs(obItem.value).format("LL"));

        switch (obItem.key) {
          case "created_at":
            set(obItem, "label", "created.date");
            break;
          case "updated_at":
            set(obItem, "label", "updated.date");
            break;
          case "deleted_at":
            set(obItem, "label", "deleted.date");
            break;
          case "valid_at":
            set(obItem, "label", "date.valid.from");
            break;
        }
      }

      return obItem;
    });
  }
}
</script>
